import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

class LoadMore extends Sargasso {
	constructor (element, options = {}) {
		options.watchViewport = true
		super(element, options)
		this.endpoint = this.element.getAttribute('data-endpoint')
		this.filters = this.element.getAttribute('data-filters')
		this.page = this.element.getAttribute('data-page') || 0
	}

	enterViewport () {
		document.body.dispatchEvent(new CustomEvent('load-more', {
			detail: {
				endpoint: this.endpoint,
				page: this.page,
				controller: this,
				filters: this.filters
			}
		}))
	}
}

utils.registerSargassoClass('LoadMore', LoadMore)

export {
	LoadMore
}
