import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

import {
	theScrollWatcher
}
	from '@pelagiccreatures/sargasso/lib/Services'

class MenuBarScrollHide extends Sargasso {
	constructor (elem, options) {
		super(elem, {
			watchScroll: true
		})
		this.lastscroll = theScrollWatcher.scrollTop()
		this.hidden = false
	}

	newPage () {
		this.show()
	}

	didScroll () {
		const scrollTop = theScrollWatcher.scrollTop()

		const delta = scrollTop - this.lastscroll

		if (scrollTop < 65) {
			this.show()
		} else {
			if (Math.abs(delta) > 20) {
				if (scrollTop > this.lastscroll) {
					this.hide()
				}
				if (scrollTop < this.lastscroll) {
					this.show()
				}
			}
		}
		this.lastscroll = scrollTop
	}

	hide () {
		if (!this.hidden) {
			this.hidden = true
			const frame = () => {
				this.addClass('hidemenu')
			}
			this.queueFrame(frame)
		}
	}

	show () {
		if (this.hidden) {
			this.hidden = false
			const frame = () => {
				this.removeClass('hidemenu')
			}
			this.queueFrame(frame)
		}
	}
}

utils.registerSargassoClass('MenuBarScrollHide', MenuBarScrollHide)

export {
	MenuBarScrollHide
}
