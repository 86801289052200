import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

import {
	theScrollWatcher
}
	from '@pelagiccreatures/sargasso/lib/Services'

class FadeScrollElement extends Sargasso {
	constructor (element, options = {}) {
		options.watchScroll = true
		super(element, options)
	}

	didScroll () {
		if (theScrollWatcher.scrollTop() > window.innerHeight / 2) {
			this.setCSS({
				opacity: 0
			})
		} else {
			this.setCSS({
				opacity: 1
			})
		}
	}
}

utils.registerSargassoClass('FadeScrollElement', FadeScrollElement)

export {
	FadeScrollElement
}
