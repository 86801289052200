import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

import {
	theScrollWatcher
}
	from '@pelagiccreatures/sargasso/lib/Services'

class FloatingScrollElement extends Sargasso {
	constructor (element, options = {}) {
		options.watchScroll = true
		super(element, options)
		this.element.parentElement.innerHeight = this.element.outerHeight + window.innerHeight
	}

	didScroll () {
		this.setCSS({
			top: (window.innerHeight + (theScrollWatcher.scrollTop() * -1)) + 'px'
		})
	}
}

utils.registerSargassoClass('FloatingScrollElement', FloatingScrollElement)

export {
	FloatingScrollElement
}
