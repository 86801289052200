// import any ES6 modules you need here and they will get bundled in

import './js/_globals'

import './modules/install-pwa'

import './modules/notifications-subscribe'

import {
	utils
}
	from '@pelagiccreatures/sargasso'

import './modules/analytics-report.js'

import {
	CMSUtils
}
	from '@pelagiccreatures/marlin/assets/app'

import './modules/vibrant'

import './modules/selectGooglePhoto'

import './modules/slides'

import './modules/immersive'

import {
	ScrollPosition
}
	from './modules/scroll-position'

import './modules/menu-bar-scroll-hide'

import './modules/scroll-down-prompt'

import './modules/floating-scroll'

import './modules/load-more'

import './modules/fade-scroll'

import {
	Session
}
	from './modules/session'

// export any ES6 modules you need in your ES5 scripts
// webpack will expose things exported here
// as App.XXX in the global namespace

const boot = () => {
	const pos = new ScrollPosition(document.body)
	pos.start()

	const session = new Session(document.body)
	session.start()

	utils.bootSargasso({
		scrollElement: document.getElementById('overscroll-wrapper') || window,
		breakpoints: {},
		hijax: {
			onError: (level, message) => {
				CMSUtils.flashAjaxStatus(level, message)
			},
			onLoading: function () {
				CMSUtils.tropicBird.progressBar(this.readyState !== 4)
			},
			onExitPage: () => {},
			onEnterPage: () => {
				CMSUtils.checkSubscription()
			},
			preFlight: (url, cb) => {
				const parsed = new URL(url, document.location.href)
				if (parsed.pathname === '/' && session.highwater) {
					if (parsed.search) {
						url += '&'
					} else {
						url += '?'
					}
					url += 'highwater=' + session.highwater
				}
				cb(null, false, url)
			}
		}
	})
}

export {
	CMSUtils,
	boot
}
