import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

class Session extends Sargasso {
	constructor (element, options = {}) {
		super(element, options)
		this.loading = false
		this.highwater = 0
	}

	start () {
		this.on('load-more', null, (e) => {
			if (!this.loading) {
				this.loading = true

				const endpoint = e.detail.endpoint
				const page = e.detail.page || 0
				const filters = e.detail.filters

				const options = {
					method: 'GET',
					headers: {
						Accept: 'application/json'
					}
				}

				fetch(endpoint + '?page=' + page + filters, options)
					.then((response) => {
						return response.json()
					})
					.then((response) => {
						this.loading = false
						if (response.status === 'ok') {
							if (response.posts.length) {
								this.renderPosts(response.posts)
								++this.highwater
								++e.detail.controller.page // increment current page
							} else {
								e.detail.controller.element.remove()
							}
						} else {
							console.log(response.status, response.message)
						}
					})
					.catch((err) => {
						console.log(err)
					})
			}
		})
	}

	renderPosts (posts) {
		const html = postsTemplate({
			posts: posts
		})

		// render markup offscreen then get a list of rendered posts
		const div = document.createElement('div')
		div.innerHTML = html
		const rendered = Array.from(div.querySelectorAll('.mdc-layout-grid__cell--span-4'))

		// append posts to post list
		const postList = document.getElementById('post-list')
		for (const item in rendered) {
			const post = rendered[item]
			this.queueFrame(() => {
				postList.append(post)
			})
		}
	}
}

utils.registerSargassoClass('Session', Session)

export {
	Session
}
