import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

class selectGooglePhotos extends Sargasso {
	constructor (elem, options) {
		super(elem, options)
	}

	start () {
		super.start()

		this.on('click', 'img', (e, element) => {
			utils.elementTools.setCSS(element, {
				opacity: 0.5
			})
			const id = element.getAttribute('id')
			const sel = JSON.parse(this.element.querySelector('textarea').value || '[]')
			if (sel.indexOf(id) !== -1) {
				sel.splice(sel.indexOf(id), 1)
			} else {
				sel.push(id)
			}
			this.element.querySelector('textarea').value = JSON.stringify(sel)
		})
	}

	sleep () {
		this.off('click', 'img')
		super.sleep()
	}
}

utils.registerSargassoClass('selectGooglePhotos', selectGooglePhotos)

export {
	selectGooglePhotos
}
