import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

import {
	elementTools
}
	from '@pelagiccreatures/sargasso/lib/utils'

import * as Utils from '@pelagiccreatures/marlin/assets/modules/utils'

class immersive extends Sargasso {
	constructor (elem, options) {
		super(elem, {
			watchOrientation: true
		})

		this.target = this.element.getAttribute('data-target') ? document.querySelector(this.element.getAttribute('data-target')) : null
	}

	start () {
		super.start()

		this.clickHandler = (e) => {
			this.wantFullscreen(!elementTools.hasClass(document.body, 'fullscreen'))
		}

		this.element.addEventListener('click', this.clickHandler, false)

		this.keyHandler = (e) => {
			if (e.keyCode === 27) {
				this.wantFullscreen(false)
			}
		}

		document.addEventListener('keydown', this.keyHandler)
	}

	sleep () {
		this.element.removeEventListener('click', this.clickHandler)
		document.removeEventListener('keydown', this.keyHandler)
		elementTools.off(this.uid, this.target, 'click', 'div')
		super.sleep()
	}

	enterFullscreen () {
		const frame = () => {
			elementTools.addClass(document.body, 'fullscreen')
			// kick the document to let everyone knows a big change occured
			document.body.dispatchEvent(new Event('resize', {
				bubbles: true
			}))
		}
		this.queueFrame(frame)
		if (this.target) {
			this.target.dispatchEvent(new CustomEvent('sargasso', {
				detail: {
					sargassoEvent: 'enterFullscreen'
				}
			}))
		}
	}

	exitFullscreen () {
		const frame = () => {
			elementTools.removeClass(document.body, 'fullscreen')
		}
		this.queueFrame(frame)
		if (this.target) {
			this.target.dispatchEvent(new CustomEvent('sargasso', {
				detail: {
					sargassoEvent: 'exitFullscreen'
				}
			}))
		}
	}
}

utils.registerSargassoClass('immersive', immersive)

export {
	immersive
}
