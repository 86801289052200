import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

import {
	elementTools
}
	from '@pelagiccreatures/sargasso/lib/utils'

import * as CMSUtils from '@pelagiccreatures/marlin/assets/modules/utils'

class slideShow extends Sargasso {
	constructor (elem, options = {}) {
		options.watchOrientation = true
		super(elem, options)
		this.currentIndex = -1
		this.slides = this.element.getElementsByClassName('slide')
		this.fullScreen = false
		this.napping = true
	}

	start () {
		super.start()

		utils.elementTools.on('slideshow-launch-handler', document.body, 'click', '.launch-slides', (e, element) => {
			const index = element.getAttribute('data-index')
			this.showSlideIndex(index)
			this.toggleFullscreen()
		})

		utils.elementTools.on('slideshow-fullscreenchange', document, 'fullscreenchange', (e) => {
			if (this.fullScreen && !document.fullscreenElement) {
				this.toggleFullscreen()
			}
		})
	}

	wakeup () {
		// console.log('wakeup', this.napping)
		if (this.napping) {
			this.napping = false
			this.on('click', '.immersive-icon', () => {
				this.toggleFullscreen()
			})

			if ('ontouchstart' in document.documentElement) {
				this.addClass('has-touch')
				this.on('touchstart', this.touchHandler.bind(this))
				this.on('touchmove', this.touchMoveHandler.bind(this))
				this.on('touchend', this.touchEndHandler.bind(this))
				this.on('touchcancel', this.touchCancelHandler.bind(this))
			} else {
				this.addClass('no-touch')

				utils.elementTools.on('slideshow-prev-next', document, 'keydown', (e) => {
					if (e.keyCode === 37) {
						this.showSlide(-1)
					}
					if (e.keyCode === 39) {
						this.showSlide(1)
					}
				})

				this.on('click', '.slide', (e) => {
					const w = this.element.clientWidth
					const offset = this.element.offsetLeft
					const x = e.pageX - offset
					let delta = 1
					if (w / 2 > x) {
						delta = -1
					}

					let newIndex = this.currentIndex + delta
					if (newIndex > this.slides.length - 1) {
						newIndex = 0
					}
					if (newIndex < 0) {
						newIndex = this.slides.length - 1
					}

					this.showSlideIndex(newIndex + 1)
				})
			}
		}
	}

	nap () {
		// console.log('nap', this.napping)
		if (!this.napping) {
			this.napping = true
			if (this.currentIndex !== -1) {
				utils.elementTools.removeClass(this.slides[this.currentIndex], 'in')
				utils.elementTools.addClass(this.slides[this.currentIndex], 'out')
				this.currentIndex = -1
			}

			utils.elementTools.off('slideshow-prev-next', document, 'keydown')
			elementTools.offAll(this.element)
		}
	}

	sleep () {
		this.nap()
		utils.elementTools.off('slideshow-launch-handler', document.body, 'click', '.launch-slides')
		utils.elementTools.off('slideshow-fullscreenchange', document, 'fullscreenchange')
		super.sleep()
	}

	enterFullscreen () {
		if (!this.fullScreen) {
			if (this.currentIndex === -1) {
				this.showSlideIndex(1)
			}

			this.toggleFullscreen()
		}
	}

	exitFullscreen () {
		if (this.fullScreen) {
			this.toggleFullscreen()
		}
	}

	toggleFullscreen () {
		if (!this.fullScreen) {
			this.wakeup()
			this.fullScreen = true
			utils.elementTools.addClass(document.body, 'fullscreen')
			utils.elementTools.on('slideshow-fullscreen-keydown', document, 'keydown', (e) => {
				if (e.keyCode === 27) {
					this.toggleFullscreen()
				}
			})
			if (this.element.requestFullscreen) {
				this.element.requestFullscreen().then(() => {}).catch(err => {})
			}
		} else {
			this.fullScreen = false
			utils.elementTools.off('slideshow-fullscreen-keydown', document, 'keydown')
			utils.elementTools.removeClass(document.body, 'fullscreen')
			if (document.fullscreenElement) {
				document.exitFullscreen()
			}
			this.nap()
		}
	}

	touchMoveHandler (event) {
		// console.log('touchmove', this.touching)

		if (this.touching) {
			event.preventDefault()

			this.deltaX = event.touches[0].pageX - this.startX

			let peek = null
			if (this.deltaX > 0) {
				peek = this.slides[this.currentIndex > 0 ? this.currentIndex - 1 : this.slides.length - 1]
			} else {
				peek = this.slides[this.currentIndex < this.slides.length - 1 ? this.currentIndex + 1 : 0]
			}
			if (peek && peek !== this.peek) {
				this.peek = peek
				utils.elementTools.removeClass(this.peek, 'out')
				utils.elementTools.addClass(this.peek, 'in')
				const lazyBackground = this.peek.children[0]
				lazyBackground.dispatchEvent(new CustomEvent('sargasso', {
					detail: {
						sargassoEvent: 'enterViewport'
					}
				}))
			}
			if (this.peek) {
				let peekLeft = 0
				if (this.deltaX < 0) {
					peekLeft = ((parseInt(this.deltaX) || 0) + this.slideWidth) + 'px'
				} else {
					peekLeft = ((parseInt(this.deltaX) || 0) - this.slideWidth) + 'px'
				}
				// this.peek.querySelector('.slide-img').style.marginLeft = peekLeft

				this.peek.style.marginLeft = peekLeft
			}
			// this.slides[this.currentIndex].querySelector('.slide-img').style.marginLeft = (parseInt(this.deltaX) || 0) + 'px'

			this.slides[this.currentIndex].style.marginLeft = (parseInt(this.deltaX) || 0) + 'px'
		}
	}

	touchEndHandler (event) {
		// console.log('touchend', this.touching)
		if (this.touching) {
			// this.slides[this.currentIndex].querySelector('.slide-img').style.marginLeft = '0px'
			this.slides[this.currentIndex].style.marginLeft = '0px'

			if ((this.timerCounter < 30 && this.deltaX > 20) || (this.deltaX >= 50)) {
				this.showSlide(-1, true)
			} else if ((this.timerCounter < 30 && this.deltaX < -20) || (this.deltaX <= -50)) {
				this.showSlide(1, true)
			} else {
				this.cancelled = true
				this.showSlide(0)
			}

			this.touchDone()
		}
	}

	touchCancelHandler (event) {
		// console.log('touchcancel', this.touching)
		this.cancelled = true
		this.touchDone()
	}

	touchHandler (event) {
		// console.log('touchstart', this.touching)
		if (!this.touching) {
			this.touching = true
			if (!this.element.contains(event.target)) {
				return
			}
			this.slideWidth = this.element.clientWidth
			this.maxMove = this.slides.length * this.slideWidth

			this.cancelled = false
			this.startX = event.touches[0].pageX

			this.timer = setInterval(() => {
				this.timerCounter++
			}, 10)
		}
	}

	touchDone () {
		// console.log('touchdone', this.cancelled)

		const peek = this.peek

		if (peek) {
			if (this.cancelled) {
				utils.elementTools.removeClass(peek, 'in')
				utils.elementTools.addClass(peek, 'out')
				peek.style.marginLeft = '0px'
				this.cancelled = false
			}

			this.peek = null
		}

		clearInterval(this.timer)
		this.timer = null
		this.timerCounter = 0
		this.touching = false
		this.deltaX = 0
	}

	showSlideIndex (index) {
		const delta = index - (this.currentIndex + 1)
		if (delta) {
			this.showSlide(delta)
		}
	}

	showSlide (delta, touch) {
		if (this.currentIndex !== -1) {
			this.element.querySelector('.slide-overlay').style.diplay = 'none'
			if (touch) { // slide out outgoing
				const currentSlide = this.slides[this.currentIndex]
				currentSlide.style.transition = 'margin .25s'
				if (delta < 0) {
					currentSlide.style.marginLeft = (this.element.clientWidth) + 'px'
				} else {
					currentSlide.style.marginLeft = (this.element.clientWidth * -1) + 'px'
				}
				utils.elementTools.once(this.uid, currentSlide, 'transitionend', (e) => {
					currentSlide.style.transition = 'margin 0s'
					currentSlide.style.marginLeft = '0px'
					utils.elementTools.removeClass(currentSlide, 'in')
					utils.elementTools.addClass(currentSlide, 'out')
				})
			} else {
				utils.elementTools.removeClass(this.slides[this.currentIndex], 'in')
				utils.elementTools.addClass(this.slides[this.currentIndex], 'out')
			}

			utils.elementTools.removeClass(this.slides[this.currentIndex].querySelector('.caption'), 'in')
			utils.elementTools.addClass(this.slides[this.currentIndex].querySelector('.caption'), 'out')
		}

		this.currentIndex += delta
		if (this.currentIndex > this.slides.length - 1) {
			this.currentIndex = 0
		}
		if (this.currentIndex < 0) {
			this.currentIndex = this.slides.length - 1
		}

		const lazyBackground = this.slides[this.currentIndex].children[0]
		lazyBackground.dispatchEvent(new CustomEvent('sargasso', {
			detail: {
				sargassoEvent: 'enterViewport'
			}
		}))

		// preload the next one
		if (this.currentIndex < this.slides.length - 1) {
			const nextFlyingFish = this.slides[this.currentIndex + 1].children[0]
			nextFlyingFish.dispatchEvent(new CustomEvent('sargasso', {
				detail: {
					sargassoEvent: 'enterViewport'
				}
			}))
		}

		if (touch) { // slide in incoming
			const currentSlide = this.slides[this.currentIndex]
			currentSlide.style.transition = 'margin .25s'
			currentSlide.style.marginLeft = '0px'
			utils.elementTools.once(this.uid, currentSlide, 'transitionend', (e) => {
				currentSlide.style.transition = 'margin 0s'
			})
		}

		utils.elementTools.removeClass(this.slides[this.currentIndex], 'out')
		utils.elementTools.addClass(this.slides[this.currentIndex], 'in')
		utils.elementTools.removeClass(this.slides[this.currentIndex].querySelector('.caption'), 'out')
		utils.elementTools.addClass(this.slides[this.currentIndex].querySelector('.caption'), 'in')

		const dot = this.element.querySelector('.dot-prompt')

		// update current dot
		setTimeout(() => {
			dot.innerHTML = (this.currentIndex + 1) + ' of ' + this.slides.length
		}, 500)
	}
}

utils.registerSargassoClass('slideShow', slideShow)

export {
	slideShow
}
