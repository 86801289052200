import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

const cache = {}

// TODO web worker thing not working.... revisit when Vibrant is updated

class VibrantController extends Sargasso {
	start () {
		super.start()
		if (cache[this.element.getAttribute('data-image')]) {
			setTimeout(() => {
				this.setColors(cache[this.element.getAttribute('data-image')])
			}, 255)
			return
		}

		// var vibrant = new Vibrant(this.element.getAttribute('data-image'))

		window.Vibrant
			.from(this.element.getAttribute('data-image'))
			.getPalette()
			.then((palette) => {
				cache[this.element.getAttribute('data-image')] = palette
				this.setColors(palette)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	setColors (swatches) {
		const top = swatches.Muted ? swatches.Muted.getHex() : '#eee'
		const bottom = swatches.DarkMuted ? swatches.DarkMuted.getHex() : '#ccc'

		const title = swatches.Muted.getTitleTextColor() || '#eee'
		const text = swatches.Muted.getBodyTextColor() || '#eee'

		let styles = '.vibrant {color:' + text + '!important;}\n'
		styles += '.vibrant-bg .vibrant-typography { color:' + text + '!important;}\n'
		styles += '.vibrant-bg .vibrant-typography a{color:' + title + '!important;}\n'
		styles += '.vibrant-bg .vibrant-typography a:hover{color:' + title + '!important;}\n'
		styles += '.vibrant-bg .vibrant-typography a:visited{color:' + title + ';}\n'
		styles += '.vibrant-bg .vibrant-typography .secondary{color:' + text + '!important;}\n'
		styles += '.vibrant-bg { transition-property: background;transition-duration: .25s;transition-timing-function: ease; background: linear-gradient(to bottom right,' + top + ',' + bottom + ')};\n'
		styles += '.vibrant-border { border-color:' + bottom + '!important};\n'

		utils.elementTools.setCSS(document.getElementById('overscroll-wrapper') || document.body, {
			background: 'linear-gradient(to bottom right,' + top + ',' + bottom + ')'
		})

		const frame = () => {
			document.getElementById('override-styles').textContent = styles
		}
		this.queueFrame(frame)
	}

	sleep () {
		const frame = () => {
			document.getElementById('override-styles').textContent = ''
		}

		utils.elementTools.setCSS(document.getElementById('overscroll-wrapper') || document.body, {
			background: 'linear-gradient(to bottom right, #ffdead, #fff3e0)'
		})

		this.queueFrame(frame)

		super.sleep()
	}
}

utils.registerSargassoClass('VibrantController', VibrantController)

export {
	VibrantController
}
