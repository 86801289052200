import {
	utils
}
	from '@pelagiccreatures/sargasso'

import {
	theScrollWatcher
}
	from '@pelagiccreatures/sargasso/lib/Services'

utils.elementTools.on('scroll-down-click', document.body, 'click', '.scroll-down', (e) => {
	const top = window.innerHeight / 2
	const element = document.getElementById('overscroll-wrapper') || window
	element.scroll({
		top: top,
		left: 0,
		behavior: 'smooth'
	})
})

utils.elementTools.on('scrolled', document.getElementById('overscroll-wrapper') || window, 'scroll', null, () => {
	if (theScrollWatcher.scrollTop() > 65) {
		utils.elementTools.addClass(document.body, 'scrolled')
	} else {
		utils.elementTools.removeClass(document.body, 'scrolled')
	}
})
