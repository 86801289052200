import {
	Sargasso, utils
}
	from '@pelagiccreatures/sargasso'

import * as CMSUtils from '@pelagiccreatures/marlin/assets/modules/utils'

import {
	theScrollWatcher
}
	from '@pelagiccreatures/sargasso/lib/Services'

function setWindowState (scope, key, val) {
	const state = getWindowState(scope)
	state[key] = val
	sessionStorage.setItem(scope, JSON.stringify(state))
}

function getWindowState (scope) {
	const state = sessionStorage.getItem(scope)
	if (state) {
		return JSON.parse(state)
	}
	return {}
}

function deleteWindowState (scope) {
	sessionStorage.removeItem(scope)
}

utils.elementTools.on('click-handler', document.body, 'click', '[data-click-it]', (e, element) => {
	setWindowState(location.pathname + location.search, 'scrollTop', theScrollWatcher.scrollTop())
	setWindowState(location.pathname + location.search, 'target', element.getAttribute('id'))
	let href = element.getAttribute('data-click-it')
	href += '?filters=' + encodeURIComponent(location.search)
	CMSUtils.loadPage(href)
}, true)

class ScrollPosition extends Sargasso {
	newPage (oldPage, page) {
		const state = getWindowState(page)
		deleteWindowState(page)
		const isScrolled = theScrollWatcher.scrollTop()
		if (isScrolled !== parseInt(state.scrollTop) && parseInt(state.scrollTop)) {
			const frame = () => {
				theScrollWatcher.scrollTop(state.scrollTop)
			}
			this.queueFrame(frame)
		}

		if (state.target) {
			const frame = () => {
				const target = document.getElementById(state.target)
				if (target) {
					utils.elementTools.addClass(target, 'animate__animated')
					utils.elementTools.addClass(target, 'animate__pulse')
					utils.elementTools.addClass(target, 'animate__faster')

					utils.elementTools.once(this.uid, target, 'animationend', '', (e) => {
						utils.elementTools.removeClass(target, 'animate__animated')
						utils.elementTools.removeClass(target, 'animate__pulse')
						utils.elementTools.removeClass(target, 'animate__faster')
					})
				}
			}
			this.queueFrame(frame)
		}
	}
}

utils.registerSargassoClass('ScrollPosition', ScrollPosition)

export {
	ScrollPosition
}
